import { useState } from "react";
import { slide as Menu } from "react-burger-menu";
import './header.css'
import logo from './images/logo.png'
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Header() {
    const [stateLng, setStateLng] = useState(true);
    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setStateLng(lng === 'en');

        console.log(lng);
    };
    return (
        <>
            <div className='header'>
                <div className='header_nav'>
                    <div className='header_logo' >
                        <img src={logo} alt="logo" />
                    </div>
                </div>
                <div className="header_right">
                    <ul className='header_nav_list'>
                        <li className='herder_nav_list_item'>
                            <Link to='/'>{t('header.homeLink1')}</Link>
                        </li>
                        <li className='herder_nav_list_item'>
                            <a href='#about'>{t('header.homeLink2')}</a>
                        </li>
                        <li className='herder_nav_list_item'>
                            <Link to='/product'>{t('header.homeLink3')}</Link>
                        </li>
                        <li className='herder_nav_list_item'>
                            <a href='#contact'>{t('header.homeLink4')}</a>
                        </li>
                        <li className='herder_nav_list_item'>
                            <a href='#service'>{t('header.homeLink5')}</a>
                        </li>
                    </ul>
                    <div className='header_btn'>
                        <div className="header_btn_lang">
                            <button onClick={() => changeLanguage('en')} className={stateLng ? 'header_btn_lang_en active' : 'header_btn_lang_en'}>EN</button>
                            <button onClick={() => changeLanguage('trk')} className={stateLng ? 'header_btn_lang_trk' : 'header_btn_lang_trk active'}>TRK</button>
                        </div>
                        <a href='#contact'>{t('header.homeLink6')}</a>
                    </div>
                </div>
            </div>
            {/* <Menu right outerContainerId={"root"} >
                <a className="menu-item" href="#">
                    Home
                </a>
                <a className="menu-item" href="#aboutus">
                    About Us
                </a>
                <a className="menu-item" href="#service">
                    Services
                </a>
                <a className="menu-item" href="#contact">
                    Contact us
                </a>
                <a className="menu-item" href='#products'>
                    Products
                </a>
            </Menu> */}
        </>
    )
}
