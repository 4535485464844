import { useState } from 'react'
import './contact.css'
import { useTranslation } from 'react-i18next'

export default function Contact() {
  const { t } = useTranslation()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')

  const handleSubmit = e => {
    e.preventDefault()

    fetch('https://adil8emailsend.vercel.app/send', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name, email }),
    })
      .then(response => {
        if (!response.ok) {
          return response.json().then(errorData => {
            throw new Error(errorData.error)
          })
        }
        return response.json()
      })
      .then(data => {
        console.log(data.status);
        setName('')
        setEmail('')
      })
      .catch(error => {
        console.error('Ошибка:', error);
      })
  }
  return (
    <main className='contact' id='contact'>
      <div className='contact_left_block'>
        <h1>{t('contact.contactTextHero1')}</h1>
        <p>{t('contact.contactTextHeroPrg')}</p>
        <form onSubmit={handleSubmit}>
          <input
            className='contact_inp_name'
            placeholder={t('contact.cardOneTitle')}
            type='text'
            id='name'
            value={name}
            onChange={e => setName(e.target.value)}
            required
          />
          <input
            className='contact_inp_phone'
            placeholder={t('contact.cardTwoTitle')}
            type='text'
            id='email'
            value={email}
            onChange={e => setEmail(e.target.value)}
            required />
          <button type='submit'>{t('contact.contactBtn')}</button>
        </form>
      </div>
    </main>
  )
}
