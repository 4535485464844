import React from 'react'

import './service.css'


import icon1 from './images/icon1.svg'
import icon2 from './images/icon2.svg'
import icon3 from './images/icon3.svg'
import { useTranslation } from 'react-i18next'

export default function Service() {
	const { t } = useTranslation();

	return (
		<main className='service' id='service'>
			<div className='service_block'>
				<div className='service_text_block'>
					<h1>{t('service.heroText')}</h1>
					<p>{t('service.heroTextPrg')}</p>
				</div>
			</div>
			<div className='service_card_block'>
				<div className='service_card'>
					<img src={icon1} alt='img' />
					<div className='service_card_text'>
						<span>{t('service.card1.title')}</span>
						<p>{t('service.card1.description')}</p>
					</div>
				</div>
				<div className='service_card'>
					<img src={icon2} alt='img' />
					<div className='service_card_text'>
						<span>{t('service.card2.title')}</span>
						<p>{t('service.card2.description')}</p>
					</div>
				</div>
				<div className='service_card'>
					<img src={icon3} alt='img' />
					<div className='service_card_text'>
						<span>{t('service.card3.title')}</span>
						<p>{t('service.card3.description')}</p>
					</div>
				</div>
			</div>
		</main>
	)
}
