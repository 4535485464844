import React from 'react';
import { useTranslation } from 'react-i18next';

import './product.css';

import hero from './images/hero.png';
import bg from './images/bg.png';
import list1 from './images/list1.png';
import list2 from './images/list2.png';
import list3 from './images/list3.png';
import box1 from './images/box1.png';
import box2 from './images/box2.png';

export default function Product() {
	const { t } = useTranslation();

	const images = [list1, list2, list3]; // массив изображений для каждой секции

	return (
		<main className='product'>
			<div className='product_hero' style={{ backgroundImage: `url(${hero})` }}>
				<h1>{t('product.heroTitle')}</h1>
			</div>
			<div className='product_list' style={{ backgroundImage: `url(${bg})` }}>
				<img className='box1' src={box1} alt='Decoration' />
				<img className='box2' src={box2} alt='Decoration' />

				{t('product.sections', { returnObjects: true }).map((section, index) => (
					<div className='product_list1' key={index}>
						<div className='product_list_block1'>
							<h1>{section.title}</h1>
							<p>{section.description}</p>
						</div>
						<div className='product_list_block2'>
							<ul>
								{section.points.map((point, idx) => (
									<li key={idx}>
										<strong>{point.title}</strong> {point.text}
									</li>
								))}
							</ul>
							<img src={images[index]} alt='Illustration' />
						</div>
					</div>
				))}
			</div>
		</main>
	);
}
