import React from 'react'

import './footer.css'
import logo from './images/logo.png'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export default function Footer() {
    const { t } = useTranslation()

    return (
        <footer className='footer'>
            <div className='footer_up'>
                <div className='footer_up_left'>
                    <div className='footer_logo'>
                        <img src={logo} alt='logo' />
                    </div>
                </div>
                <div className='footer_nav'>
                    <Link to='/'>Home</Link>
                    <a href='#about'>{t('header.homeLink2')}</a>
                    <Link to='/product'>{t('header.homeLink3')}</Link>
                    <a href='#contact'>{t('header.homeLink4')}</a>
                    <a href='#service'>{t('header.homeLink5')}</a>
                </div>
                <div className='footer_up_right'>
                    <div className='footer_loc'>
                        <div className='footer_number_email'>
                            <span>37, BLOCK 33 A, STREET YUNUS EMRE B40, BASAK MAH., BASAKSEHIR DISTRICT, ISTANBUL, TURKEY</span>
                            <a href='tel:+902124567890'>+90 212 456 7890</a>
                            <a href='mailto:rfn.ic.vedis@gmail.com'>new.waygroup01@gmail.com</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer_down'>
                <span>© Copyright 2019</span>
            </div>
        </footer>
    )
}
