import React from 'react'

import './home.css'

import AboutUs from '../aboutus/AboutUs'
import Service from '../service/Service'
import Contact from '../contact/Contact'


import homeHeroImg from './images/hero.png'
import bg from './images/bg.png'
import box from './images/box.png'
import { useTranslation } from 'react-i18next'

export default function Home() {
    const { t } = useTranslation();

    return (
        <div >
            <main className='hero' style={{ backgroundImage: `url(${homeHeroImg})` }}>
                <div className='hero_text'>
                    <h1>{t('home.homeTextHero')}</h1>
                    <p>{t('home.homeTextHeroPrg')}</p>
                    <a href='#products'>{t('home.homeTextHeroBtn')}</a>
                </div>
                <img src={box} alt='box' />
            </main>
            <div className='bg' style={{ backgroundImage: `url(${bg})` }} >
                <AboutUs />
                <Service />
                <Contact />
            </div>
        </div>
    )
}
