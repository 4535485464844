import React from 'react'
import './about.css'
import { useTranslation } from 'react-i18next'

export default function AboutUs() {

	const { t } = useTranslation()

	return (
		<main className='about' id='about'>
			<div className='about_block1'>
				<h1>{t('about.aboutUs.title')}</h1>
				<p>{t('about.aboutUs.prgf')}</p>
			</div>
			<div className='about_block2'>
				<div className='about_block2_title'>
					<h2>{t('about.aboutUsTitle.aboutUsTitleStart1')}</h2>
					<p>{t('about.aboutUsTitle.aboutUsTitlePrg1')}</p>
				</div>
				<div className='about_block2_title'>
					<h2>{t('about.aboutUsTitle.aboutUsTitleStart2')}</h2>
					<p>{t('about.aboutUsTitle.aboutUsTitlePrg2')}</p>
				</div>
			</div>
			<div className='about_block3'>
				<h3>{t('about.aboutUsTitle2.aboutUs2Title')}</h3>
				<div className='about_block3_list'>
					<ul>
						<li><strong>{t('about.aboutUsTitle2.aboutUsTitleStart1')}</strong>{t('about.aboutUsTitle2.aboutUsTitlePrg1')}</li>
						<li><strong>{t('about.aboutUsTitle2.aboutUsTitleStart2')}</strong>{t('about.aboutUsTitle2.aboutUsTitlePrg2')}</li>
					</ul>
					<ul>
						<li><strong>{t('about.aboutUsTitle2.aboutUsTitleStart3')}</strong>{t('about.aboutUsTitle2.aboutUsTitlePrg3')}</li>
						<li><strong>{t('about.aboutUsTitle2.aboutUsTitleStart4')}</strong>{t('about.aboutUsTitle2.aboutUsTitlePrg4')}</li>
					</ul>
					<ul>
						<li><strong>{t('about.aboutUsTitle2.aboutUsTitleStart5')}</strong>{t('about.aboutUsTitle2.aboutUsTitlePrg5')}</li>
						<li><strong>{t('about.aboutUsTitle2.aboutUsTitleStart6')}</strong>{t('about.aboutUsTitle2.aboutUsTitlePrg6')}</li>
					</ul>
				</div>
			</div>
		</main>
	)
}
